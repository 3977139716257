<script>
import Layout from "../../layouts/main";

import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description"
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      users: [],
      Users: {},
      roles: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchUsers: null,
      SearchMode: false,
      new_roles: [],
      newRoles: [{}],
      addRoles: [{}],
      editRoles: [{}],
      branches: [],
      user_info: {},
      new_pass: "",
      users_switch: "",
      user_status: 0,
      data: {},
      selectedUser: {},
      cashAccounts: [],
    };
  },
  methods: {
    editUser() {
      let cash_account_id = null;
      if (this.checkEditRoles) {
        cash_account_id = this.selectedUser?.cash_account_id;
      }
      this.http
        .put("users", this.selectedUser.id, {
          roles: this.editRoles,
          name: this.selectedUser?.name,
          email: this.selectedUser?.email,
          cash_account_id,
        })
        .then(() => {
          this.get(this.page);
        });
    },
    addUsers() {
      this.data.new_roles = this.addRoles;
      this.http.post("users/register", this.data).then(() => {
        this.data = {};
        this.addRoles = [{}];
        this.get(this.page);
      });
    },
    search() {
      this.SearchMode = true;
      console.log(this.searchUsers);
      this.tot_pages = 0;
      this.http
        .post("users/search", {
          limit: this.limit,
          page: this.page,
          search: this.searchUsers,
          col: "name",
        })
        .then((res) => {
          this.users = res.data;
        });
    },
    cancelSearchMode() {
      this.searchUsers = "";
      this.SearchMode = false;
      this.get(this.page);
    },
    getUsers() {
      this.http.get("users").then((res) => {
        this.users = res.data;
      });
    },
    selectUser(user) {
      this.selectedUser = user;
      this.editRoles = this.selectedUser?.user_roles.map((user_role) => {
        return {
          id: user_role?.id,
          role_id: user_role?.role?.id,
          name: user_role?.role?.role,
          name_en: user_role?.role?.role_en,
        };
      });
    },
    deleteUser(user) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("users", user.id).then(() => {

            this.get(this.page);
          });
        }
      });
    },
    userInfo(user) {
      this.user_info = user;
      console.log(this.user_info);
    },
    editPass() {
      console.log(this.user_info.id);
      this.http
        .post("users/update-password", {
          user_id: this.user_info.id,
          pass: this.new_pass,
        })
        .then((res) => {
          if (res.status) {
            this.new_pass = "";
          }
        });
    },
    getCashAccounts() {
      this.http.get("cash-accounts/cash").then((res) => {
        this.cashAccounts = res.data;
      });
    },
    get(page) {
      console.log(this.userstable);
      this.http
        .post("users/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          if (res.status) {
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            this.users = res.data;
          }
        });
    },
    getRoles() {
      this.http.get("roles").then((res) => {
        this.roles = res.data;
      });
    },
    getSwitch(user, status) {
      console.log(status);
      this.http
        .post("users/update-status", {
          status: status,
          user_id: user.id,
        })
        .then(() => {
          this.get(this.page);
        });
    },
    deleteRoleOption(index, type) {
      if (type == "add") return this.addRoles.splice(index, 1);
      this.editRoles.splice(index, 1);
    },
  },
  computed: {
    checkAddRoles() {
      let isEdit = false;
      this.addRoles.forEach((item) => {
        this.roles.forEach((role) => {
          if (item?.role_id == role?.id && role?.code == "cashier") {
            isEdit = true;
          }
        });
      });
      return isEdit;
    },
    checkEditRoles() {
      let isEdit = false;
      this.editRoles.forEach((item) => {
        this.roles.forEach((role) => {
          if (item?.role_id == role?.id && role?.code == "cashier") {
            isEdit = true;
          }
        });
      });
      return isEdit;
    },
  },
  created() {
    this.get(1);
    this.getRoles();
    this.getCashAccounts();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.users.text')"
    />
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <div class="search-box chat-search-box" style="width: 250px">
              <div class="position-relative">
                <input
                  @keyup="search()"
                  type="text"
                  class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
                  :placeholder="$t('users.searchplaceholder')"
                  v-model="searchUsers"
                  style="background-color: #2a3042 !important"
                />
                <i class="bx bx-search-alt search-icon text-light"></i>
                <button
                  @click="cancelSearchMode()"
                  v-if="SearchMode"
                  class="btn-close text-light"
                  style="position: absolute; top: 12px; left: 250px"
                ></button>
              </div>
            </div>
          </div>
          <div class="col-6" style="display: flex; justify-content: flex-end">
            <button
              type="button"
              class="btn btn-light float-end mb-4"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </div>
        </div>

        <div class="table-responsive">
          <table
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="table table-centered table-nowrap table-striped table-hover align-middle"
          >
            <thead>
              <tr
                class="text-light"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">#</th>
                <th scope="col">{{ $t("users.userstable.user") }}</th>
                <th scope="col">{{ $t("users.userstable.email") }}</th>
                <th scope="col">{{ $t("users.userstable.roles") }}</th>
                <th scope="col">{{ $t("users.userstable.created") }}</th>
                <th scope="col">{{ $t("users.userstable.updated") }}</th>
                <th scope="col">
                  {{ $t("users.userstable.operations") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="" v-for="(user, index) in users" :key="user">
                <td>{{ index + 1 }}</td>
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>
                  <button
                    class="btn btn-primary me-2 btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#showRolesModal"
                    @click="selectUser(user)"
                  >
                    <span class="bx bx-show-alt fa-lg text-light"></span>
                  </button>
                </td>
                <td>{{ user.created.split("T")[0] }}</td>
                <td>{{ user.updated.split("T")[0] }}</td>
                <td>
                  <button
                    @click="getSwitch(user, 1)"
                    v-if="user.status == 0"
                    class="btn btn-primary btn-sm"
                    style="margin-inline-end: 4px"
                  >
                    {{ $t("popups.enable") }}
                  </button>
                  <button
                    type="button"
                    @click="getSwitch(user, 0)"
                    v-if="user.status == 1"
                    class="btn btn-secondary btn-sm"
                    style="margin-inline-end: 4px"
                  >
                    {{ $t("popups.disable") }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    style="margin-inline-end: 4px"
                    data-bs-target="#editModal"
                    data-bs-toggle="modal"
                    @click="selectUser(user)"
                  >
                    {{ $t("popups.edit") }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    style="margin-inline-end: 4px"
                    data-bs-target="#editPassModal"
                    data-bs-toggle="modal"
                    @click="userInfo(user)"
                  >
                    {{ $t("popups.resetpass") }}
                  </button>
                  <a
                    class="btn btn-danger btn-sm"
                    style="margin-inline-end: 4px"
                    :class="{}"
                    href="javascript:void(0)"
                    role="button"
                    @click="deleteUser(user)"
                    >{{ $t("popups.delete") }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- end  table -->

    <!-- start show roles modal -->
    <div
      class="modal fade"
      id="showRolesModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("users.roles") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              style="margin: 0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body p-4">
            <div class="table-responsive">
              <table
                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                class="table table-centered table-nowrap table-striped table-hover align-middle"
              >
                <thead>
                  <tr
                    class="text-light"
                    style="background-color: #2a3042 !important"
                  >
                    <th scoped="col">#</th>
                    <th scoped="col">{{ $t("users.role") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(userRole, index) in selectedUser?.user_roles"
                    :key="userRole"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                          ? userRole?.role?.role
                          : userRole?.role?.role_en
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end show roles modal -->
    <!--Start Add Modal-->
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="addUsers()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.adduser") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">

              <div class="mb-3">
                <label for="usename">{{ $t("popups.username") }}</label>
                <input
                  v-model="data.name"
                  id="user"
                  required
                  class="form-control"
                  type="text"
                  :placeholder="$t('popups.username')"
                />
              </div>

              <div class="mb-3">
                <label for="user">{{ $t("popups.email") }}</label>
                <input
                  v-model="data.email"
                  id="user"
                  class="form-control"
                  type="text"
                  :placeholder="$t('popups.email')"
                />
              </div>

              <div class="mb-3">
                <label for="pass">{{ $t("popups.password") }}</label>
                <input
                  v-model="data.pass"
                  id="pass"
                  required
                  class="form-control"
                  type="text"
                  :placeholder="$t('popups.password')"
                />
              </div>
              <div class="mb-3">
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="user_role">{{ $t("popups.role") }}</label>
                  </div>
                  <div class="col d-flex justify-content-end">
                    <button
                      type="button"
                      @click="addRoles.push({})"
                      class="btn float-end"
                    >
                      <span
                        class="bx bxs-plus-circle float-end fa-2x"
                        style="color: #2a3042 !important"
                      ></span>
                    </button>
                  </div>
                </div>
                <div v-for="(role, index) in addRoles" :key="role">
                  <div class="row mt-2">
                    <div class="col">
                      <select
                        v-model="role.role_id"
                        style="display: inline"
                        class="form-select"
                        id="add_user_role"
                      >
                        <option v-for="r in roles" :key="r" :value="r.id">
                          {{ $i18n.locale == "ar" ? r.role : r.role_en }}
                        </option>
                      </select>
                    </div>
                    <div class="col-2">
                      <button
                        type="button"
                        class="btn btn-danger"
                        :disabled="addRoles.length <= 1"
                        @click="deleteRoleOption(index, 'add')"
                      >
                        <i class="bx bx-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3" v-if="checkAddRoles">
                <label for="exampleInputEmail1" class="form-label">
                  {{ $t("users.cash_account") }}
                </label>
                <select
                required
                  class="form-select"
                  aria-label="Default select example"
                  v-model="data.cash_account_id"
                >
                  <option v-for="i in cashAccounts" :key="i" :value="i.id">
                    {{
                      $i18n.locale == "ar"
                        ? i.code + "-" + i?.name
                        : i.code + "-" + i?.name_en
                    }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.add") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Add Modal-->

    <!-- Edit Modal -->
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="editUser()">
            <div class="modal-header" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.edituser") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
              <div class="mb-3">
                <label for="user">{{ $t("popups.username") }}</label>
                <input
                  id="user"
                  required
                  class="form-control"
                  type="text"
                  :placeholder="$t('popups.username')"
                  v-model="selectedUser.name"
                />
              </div>

              <div class="mb-3">
                <label for="user">{{ $t("popups.email") }}</label>
                <input
                  v-model="selectedUser.email"
                  id="user"
                  required
                  class="form-control"
                  type="text"
                  :placeholder="$t('popups.email')"
                />
              </div>

              <div class="mb-3">
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="user_role">{{ $t("popups.role") }}</label>
                  </div>
                  <div class="col d-flex justify-content-end">
                    <button
                      type="button"
                      @click="editRoles.push({})"
                      class="btn float-end"
                    >
                      <span
                        class="bx bxs-plus-circle float-end fa-2x"
                        style="color: #2a3042 !important"
                      ></span>
                    </button>
                  </div>
                </div>
                <div v-for="(user_role, index) in editRoles" :key="user_role">
                  <div class="row mt-2">
                    <div class="col">
                      <select
                        v-model="user_role.role_id"
                        style="display: inline"
                        class="form-select"
                        id="edit_user_role"
                      >
                        <option
                          v-for="role in roles"
                          :key="role"
                          :value="role.id"
                          :selected="user_role.role_id == role?.id"
                        >
                          {{ $i18n.locale == "ar" ? role.role : role.role_en }}
                        </option>
                      </select>
                    </div>
                    <div class="col-2">
                      <button
                        type="button"
                        class="btn btn-danger"
                        :disabled="editRoles.length <= 1"
                        @click="deleteRoleOption(index, 'edit')"
                      >
                        <i class="bx bx-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3" v-if="checkEditRoles">
                <label for="exampleInputEmail1" class="form-label">
                  {{ $t("users.cash_account") }}
                </label>
                <select
                required
                  class="form-select"
                  aria-label="Default select example"
                  v-model="selectedUser.cash_account_id"
                >
                  <option v-for="i in cashAccounts" :key="i" :value="i.id">
                    {{
                      $i18n.locale == "ar"
                        ? i.code + "-" + i?.name
                        : i.code + "-" + i?.name_en
                    }}
                  </option>
                </select>
              </div>
            </div>
            <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
              <button
              data-bs-dismiss="modal"
               type="submit" class="btn btn-primary">
                {{ $t("popups.edit") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--end Edit Modal-->
    <!--Edit Pass Modsl-->

    <div
      class="modal fade"
      id="editPassModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="editPass()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.resetpass") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <label for="pass" class="form-label">{{
                $t("popups.resetpass")
              }}</label>
              <input
                v-model="new_pass"
                required
                type="password"
                class="form-control"
                id="pass"
                :placeholder="$t('popups.resetpass')"
              />
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.edit") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--Edit pass Modal-->
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
    <!--end-->
  </Layout>
</template>
